var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return Object.keys(_vm.blogEdit).length
    ? _c(
        "b-card",
        { staticClass: "blog-edit-wrapper" },
        [
          _c(
            "b-media",
            { attrs: { "no-body": "", "vertical-align": "center" } },
            [
              _c(
                "b-media-aside",
                { staticClass: "mr-75" },
                [
                  _c("b-avatar", {
                    attrs: { size: "38", src: _vm.blogEdit.avatar }
                  })
                ],
                1
              ),
              _c(
                "b-media-body",
                [
                  _c("h6", { staticClass: "mb-25" }, [
                    _vm._v(" " + _vm._s(_vm.blogEdit.userFullName) + " ")
                  ]),
                  _c("b-card-text", [_vm._v(_vm._s(_vm.blogEdit.createdTime))])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-form",
            { staticClass: "mt-2" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: "Title",
                            "label-for": "blog-edit-title"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: { id: "blog-edit-title" },
                            model: {
                              value: _vm.blogEdit.blogTitle,
                              callback: function($$v) {
                                _vm.$set(_vm.blogEdit, "blogTitle", $$v)
                              },
                              expression: "blogEdit.blogTitle"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: "Category",
                            "label-for": "blog-edit-category"
                          }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              id: "blog-edit-category",
                              dir: _vm.$store.state.appConfig.isRTL
                                ? "rtl"
                                : "ltr",
                              multiple: "",
                              options: _vm.categoryOption
                            },
                            model: {
                              value: _vm.blogEdit.blogCategories,
                              callback: function($$v) {
                                _vm.$set(_vm.blogEdit, "blogCategories", $$v)
                              },
                              expression: "blogEdit.blogCategories"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: "Slug",
                            "label-for": "blog-edit-slug"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: { id: "blog-edit-slug" },
                            model: {
                              value: _vm.blogEdit.slug,
                              callback: function($$v) {
                                _vm.$set(_vm.blogEdit, "slug", $$v)
                              },
                              expression: "blogEdit.slug"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: "Status",
                            "label-for": "blog-edit-category"
                          }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              id: "blog-edit-category",
                              dir: _vm.$store.state.appConfig.isRTL
                                ? "rtl"
                                : "ltr",
                              options: _vm.statusOption
                            },
                            model: {
                              value: _vm.blogEdit.status,
                              callback: function($$v) {
                                _vm.$set(_vm.blogEdit, "status", $$v)
                              },
                              expression: "blogEdit.status"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: "Content",
                            "label-for": "blog-content"
                          }
                        },
                        [
                          _c("quill-editor", {
                            attrs: {
                              id: "blog-content",
                              options: _vm.snowOption
                            },
                            model: {
                              value: _vm.blogEdit.excerpt,
                              callback: function($$v) {
                                _vm.$set(_vm.blogEdit, "excerpt", $$v)
                              },
                              expression: "blogEdit.excerpt"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-col", { staticClass: "mb-2", attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "border rounded p-2" },
                      [
                        _c("h4", { staticClass: "mb-1" }, [
                          _vm._v(" Featured Image ")
                        ]),
                        _c(
                          "b-media",
                          {
                            staticClass: "flex-column flex-md-row",
                            attrs: { "no-body": "", "vertical-align": "center" }
                          },
                          [
                            _c(
                              "b-media-aside",
                              [
                                _c("b-img", {
                                  ref: "refPreviewEl",
                                  staticClass: "rounded mr-2 mb-1 mb-md-0",
                                  attrs: {
                                    src: _vm.blogEdit.featuredImage,
                                    height: "110",
                                    width: "170"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-media-body",
                              [
                                _c("small", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    "Required image resolution 800x400, image size 10mb."
                                  )
                                ]),
                                _c(
                                  "b-card-text",
                                  { staticClass: "my-50" },
                                  [
                                    _c(
                                      "b-link",
                                      { attrs: { id: "blog-image-text" } },
                                      [
                                        _vm._v(
                                          " C:\\fakepath\\" +
                                            _vm._s(
                                              _vm.blogFile
                                                ? _vm.blogFile.name
                                                : "banner.jpg"
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-inline-block" },
                                  [
                                    _c("b-form-file", {
                                      ref: "refInputEl",
                                      attrs: {
                                        accept: ".jpg, .png, .gif",
                                        placeholder: "Choose file"
                                      },
                                      on: { input: _vm.inputImageRenderer },
                                      model: {
                                        value: _vm.blogFile,
                                        callback: function($$v) {
                                          _vm.blogFile = $$v
                                        },
                                        expression: "blogFile"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "mt-50", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple.400",
                              value: "rgba(255, 255, 255, 0.15)",
                              expression: "'rgba(255, 255, 255, 0.15)'",
                              modifiers: { "400": true }
                            }
                          ],
                          staticClass: "mr-1",
                          attrs: { variant: "primary" }
                        },
                        [_vm._v(" Save Changes ")]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple.400",
                              value: "rgba(186, 191, 199, 0.15)",
                              expression: "'rgba(186, 191, 199, 0.15)'",
                              modifiers: { "400": true }
                            }
                          ],
                          attrs: { variant: "outline-secondary" }
                        },
                        [_vm._v(" Cancel ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }